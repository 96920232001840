<template>
  <v-container>
    <v-toolbar-title align="center">
      {{ title1 }} <br />
      {{ title2 }}
    </v-toolbar-title>
    <v-form v-model="valid">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="step > 1">Général</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="step > 2">
            Identification
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="step > 3">
            Renseignements
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="4">Validation</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-select
              v-model="select0"
              :items="typo"
              :rules="[v => !!v || 'Merci de choisir pour continuer']"
              label="Vous êtes un ?"
              required
            ></v-select>

            <v-btn
              :disabled="!this.select0"
              align="center"
              color="primary"
              class="mr-4"
              @click.native="step = 2"
            >
              suivant
            </v-btn>
          </v-stepper-content>
          <!-- ETAPE 2 PARTICULIER -->
          <v-stepper-content v-if="this.select0 === 'Particulier'" step="2">
            <v-row no-gutters class="pa-2">
              <v-col cols="1">
                <v-select
                  v-model="select"
                  :items="genre"
                  :rules="nameRules"
                  label="Titre"
                  required
                ></v-select>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  label="Nom"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-text-field
                  v-model="firstname"
                  :rules="nameRules"
                  label="Prénom"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="2">
                <v-text-field
                  v-model="birthdate"
                  :rules="dateRules"
                  label="Né(e) le"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="9">
                <v-text-field
                  v-model="birthplace"
                  label="à (Ville de naissance et numéro de département)"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn
              align="center"
              color="secondary"
              class="mr-4"
              @click="reload"
            >
              réinitialiser
            </v-btn>
            <v-btn
              align="center"
              color="primary"
              class="mr-4"
              @click.native="step = 3"
            >
              suivant
            </v-btn>
          </v-stepper-content>
          <!-- ETAPE 2 PRO -->
          <v-stepper-content
            v-if="this.select0 === 'Professionnel ou autre'"
            step="2"
          >
            <v-row no-gutters class="pa-2">
              <v-col cols="12">
                <v-select
                  v-model="select2"
                  :items="type"
                  :rules="nameRules"
                  label="Type"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-2">
              <v-col cols="9">
                <v-text-field
                  v-model="denomination"
                  :rules="nameRules"
                  label="Dénomination sociale ou organisation"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-model="forme"
                  :rules="nameRules"
                  label="Forme juridique (SA, SARL,...)"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-2">
              <v-col cols="12">
                <v-text-field
                  v-model="nom"
                  label="Nom commercial"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="8">
                <v-text-field
                  v-model="siret"
                  label="N° d'identification (SIRET)"
                  :rules="siretRules"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="naf"
                  label="Activité (code NAF)"
                  :rules="nafRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-2">
              <v-col cols="12">
                <v-text-field
                  v-model="address2"
                  label="Adresse"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="2">
                <v-text-field
                  v-model="cp2"
                  label="Code postal"
                  :rules="cpRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="town2"
                  label="Ville"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              align="center"
              color="secondary"
              class="mr-4"
              @click="reload"
            >
              réinitialiser
            </v-btn>
            <v-btn
              align="center"
              color="primary"
              class="mr-4"
              @click.native="step = 3"
            >
              suivant
            </v-btn>
          </v-stepper-content>
          <!-- ETAPE 3 PARTICULIER -->
          <v-stepper-content v-if="this.select0 == 'Particulier'" step="3">
            <v-row no-gutters class="pa-2">
              <v-col cols="12">
                <v-text-field
                  v-model="address"
                  label="Adresse"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="2">
                <v-text-field
                  v-model="cp"
                  label="Code postal"
                  :rules="cpRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="town"
                  label="Ville"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="5">
                <v-text-field
                  v-model="tel"
                  :rules="telRules"
                  label="Téléphone"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              align="center"
              color="secondary"
              class="mr-4"
              @click.native="step = 2"
            >
              précédent
            </v-btn>
            <v-btn
              align="center"
              color="primary"
              class="mr-4"
              @click.native="step = 4"
            >
              suivant
            </v-btn>
          </v-stepper-content>
          <!-- ETAPE 3 PRO -->
          <v-stepper-content
            v-if="this.select0 === 'Professionnel ou autre'"
            step="3"
          >
            <v-card-subtitle class="d-flex">
              Représenté par (signataire du présent document) :
            </v-card-subtitle>

            <v-row no-gutters class="pa-2">
              <v-col cols="1">
                <v-select
                  v-model="select3"
                  :items="genre"
                  :rules="nameRules"
                  label="Titre"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="name2"
                  :rules="nameRules"
                  label="Nom"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  v-model="firstname2"
                  :rules="nameRules"
                  label="Prénom"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="12">
                <v-text-field
                  v-model="address3"
                  label="Adresse professionnelle"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="2">
                <v-text-field
                  v-model="cp3"
                  label="Code postal"
                  :rules="cpRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <v-text-field
                  v-model="town3"
                  label="Ville"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters class="pa-2">
              <v-col cols="5">
                <v-text-field
                  v-model="tel2"
                  :rules="telRules"
                  label="Téléphone"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  v-model="email2"
                  :rules="emailRules"
                  label="E-mail"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              align="center"
              color="secondary"
              class="mr-4"
              @click.native="step = 2"
            >
              précédent
            </v-btn>
            <v-btn
              align="center"
              color="primary"
              class="mr-4"
              @click.native="step = 4"
            >
              suivant
            </v-btn>
          </v-stepper-content>
          <!-- ETAPE 4 -->
          <v-stepper-content step="4">
            Données à recueillir :
            <v-row>
              <v-col cols="6">
                <v-checkbox
                  v-model="conso"
                  label="L’historique des consommations, en kWh, du site (et puissances atteintes et dépassements de puissance) ;"
                ></v-checkbox>
                <v-checkbox
                  v-model="histo"
                  label="L’historique de courbe de charge du site ;"
                ></v-checkbox>
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="relev"
                  label="L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site ;"
                ></v-checkbox>
                <v-checkbox
                  v-model="contrat"
                  label="Les données techniques et contractuelles disponibles du site."
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-2">
              <v-col cols="7">
                <v-text-field
                  v-model="usage"
                  label="Usage des données (conseil énergétique, études, ...)"
                  clearable
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-text-field
                  v-model="duree"
                  label="Durée de l'autorisation (par défaut 1 mois)"
                  :rules="dureeRules"
                  suffix="mois"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="pa-2">
              <v-col cols="6">
                <v-text-field
                  v-model="faita"
                  label="Fait à (ville)"
                  :rules="nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="5">
                <v-text-field
                  v-model="faitle"
                  :rules="dateRules"
                  label="le"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              align="center"
              color="secondary"
              class="mr-4"
              @click.native="step = 3"
            >
              précédent
            </v-btn>
            <v-btn
              :disabled="!valid"
              align="center"
              color="success"
              class="mr-4"
              @click="exportPDF"
            >
              Imprimer PDF
            </v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-form>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import jsPDF from "jspdf";
//import "jspdf-autotable";
export default {
  name: "assets",
  data() {
    return {
      title1: "AUTORISATION DE COMMUNICATION À UN TIERS ",
      title2:
        "DES DONNÉES D’UN OU PLUSIEURS SITES DE CONSOMMATION RACCORDÉS AU RÉSEAU PUBLIC DE DISTRIBUTION",
      accept1: "Par la signature de ce document, ",
      accept2:
        "le Client autorise expressément le Tiers à demander et à recevoir communication auprès d’Enedis,",
      accept3: "SA à directoire et à conseil",
      accept4:
        "de surveillance, au capital de 270 037 000 euros, immatriculée au R.C.S. de Nanterre sous le numéro 444 608 442 et dont le siège social est situé Tour Enedis,",
      accept5: "34 Place des Corolles, 92070 Paris La Défense Cedex ",
      accept6:
        "des données cochées ci-dessous, sous réserve de disponibilité :",
      accept7:
        "La présente autorisation ne peut être cédée. Elle est consentie pour une durée de ",
      accept8: " mois à compter de la date de signature.",
      accept9:
        "Le Client accepte expressément que ses données personnelles soient conservées par le Tiers et/ou Enedis à des fins de gestion et de traçabilité. Conformément à la loi",
      accept10:
        "Informatique et Libertés du 6 janvier 1978, le Client dispose d’un droit d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des",
      accept11:
        "données le concernant qu’il peut exercer sur simple demande auprès du Tiers et/ou d’Enedis, 34 place des Corolles, 92079 Paris La Défense Cedex.",
      step: 1,
      name: "",
      firstname: "",
      address: "",
      cp: "",
      town: "",
      email: "",
      tel: "",
      birthdate: "",
      birthplace: "",
      denomination: "",
      forme: "",
      nom: "",
      siret: "",
      naf: "",
      name2: "",
      firstname2: "",
      address2: "",
      cp2: "",
      town2: "",
      address3: "",
      cp3: "",
      town3: "",
      email2: "",
      tel2: "",
      select0: null,
      select: null,
      select2: null,
      select3: null,
      usage: "Etude et conseils énergétiques",
      duree: "12",
      faita: "",
      faitle: "Aujourd'hui",

      maxWidth: "100%",
      valid: true,
      nameRules: [
        v => !!v || "Requis"
        //v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      emailRules: [
        v => !!v || "Requis",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      telRules: [
        v => !!v || "Requis",
        v => /^[0]\d{9}$/.test(v) || "10 chiffres commençant par 0"
      ],
      dateRules: [
        v => !!v || "JJ/MM/AAAA",
        v =>
          /^[0-9]{2}["/"]{1}[0-9]{2}["/"]{1}[0-9]{4}$/.test(v) ||
          /^[A-z]{7}["'"][a-z]{3}$/.test(v) ||
          "JJ/MM/AAAA"
      ],
      cpRules: [
        v => !!v || "CP obligatoire",
        v => /^[0-9]{5}$/.test(v) || "XXXXX"
      ],
      siretRules: [
        v => !!v || "9 + 5 chiffres sans espace",
        v => /^[0-9]{14}$/.test(v) || "XXXXXXXXXXXXXXX"
      ],
      nafRules: [
        v => !!v || "4 chiffres + 1 lettre sans espace",
        v => /^[0-9]{4}[A-Z]{1}$/.test(v) || "XXXXX"
      ],
      dureeRules: [v => !!v || "en mois", v => /^\d+$/.test(v) || "en mois"],
      genre: ["M.", "Mme.", "-"],
      type: [
        "Entreprise",
        "Collectivité locale (commune, département, ...)",
        "EPCI (syndicat de gestion...)",
        "Association, copropriété..."
      ],
      typo: ["Particulier", "Professionnel ou autre"],
      histo: true,
      conso: true,
      relev: true,
      contrat: true
    };
  },

  computed: {
    ...mapGetters(["isAdminUser", "getUserId", "getUserData"])
    /*,
    firstname() {
      return this.getUserData.firstname;
    },

    name() {
      return this.getUserData.realname;
    }*/
  },
  methods: {
    exportPDF() {
      var doc = new jsPDF("p", "pt");
      doc.setDrawColor(0);
      doc.setFillColor(200, 200, 200);
      doc.rect(35, 58, 530, 15, "FD");
      doc.rect(35, 58, 530, 200, "D");
      //doc.setLineWidth(0.1);
      //doc.setLineDash([5, 5], 0);
      //doc.line(95, 88, 290, 88);
      doc.setFontSize(9);
      doc.setFont("helvetica", "bold");
      doc.text(this.title1, 40, 40);
      doc.text(this.title2, 40, 54);
      doc.setTextColor(255);
      doc.text("CLIENT (" + this.select0.toLowerCase() + ")", 40, 68);
      doc.setTextColor(0);
      doc.setFont("helvetica", "normal");
      if (this.select0 === "Particulier") {
        doc.text(
          this.select + " " + this.name.toUpperCase() + " " + this.firstname,
          40,
          85
        );
        if (this.select === "M.") {
          doc.text(
            "Né le : " + this.birthdate + " à " + this.birthplace,
            40,
            100
          );
        } else if (this.select === "Mme") {
          doc.text(
            "Née le : " + this.birthdate + " à " + this.birthplace,
            40,
            100
          );
        } else {
          doc.text(
            "Né(e) le : " + this.birthdate + " à " + this.birthplace,
            40,
            100
          );
        }
        doc.text(
          "Adresse : " + this.address + " - " + this.cp + " " + this.town,
          40,
          115
        );
        doc.text(
          "N° téléphone : " +
            this.tel.slice(0, 2) +
            "." +
            this.tel.slice(2, 4) +
            "." +
            this.tel.slice(4, 6) +
            "." +
            this.tel.slice(6, 8) +
            "." +
            this.tel.slice(8, 10) +
            "       E-mail : " +
            this.email,
          40,
          130
        );
      } else {
        doc.text(this.select2, 40, 85);
        doc.text(
          "Dénomination sociale : " +
            this.denomination +
            "     Forme juridique : " +
            this.forme,
          40,
          100
        );
        doc.text("Nom commercial : " + this.nom, 40, 115);
        doc.text(
          "N° d’identification (SIRET) : " +
            this.siret.slice(0, 3) +
            " " +
            this.siret.slice(3, 6) +
            " " +
            this.siret.slice(6, 9) +
            " " +
            this.siret.slice(9, 14) +
            "     Activité (code NAF) : " +
            this.naf.slice(0, 4) +
            " " +
            this.naf.slice(4, 5),
          40,
          130
        );
        doc.text(
          "Adresse : " + this.address2 + " - " + this.cp2 + " " + this.town2,
          40,
          145
        );
        doc.setFont("helvetica", "bold");
        doc.text("Représenté par (signataire du présent document) :", 40, 175);
        doc.setFont("helvetica", "normal");
        doc.text(
          this.select3 + " " + this.name2.toUpperCase() + " " + this.firstname2,
          40,
          190
        );
        doc.text(
          "Adresse professionnelle : " +
            this.address3 +
            " - " +
            this.cp3 +
            " " +
            this.town3,
          40,
          205
        );
        doc.text(
          "N° téléphone : " +
            this.tel2.slice(0, 2) +
            "." +
            this.tel2.slice(2, 4) +
            "." +
            this.tel2.slice(4, 6) +
            "." +
            this.tel2.slice(6, 8) +
            "." +
            this.tel2.slice(8, 10) +
            "       E-mail : " +
            this.email2,
          40,
          220
        );
        doc.setFont("helvetica", "bold");
        doc.text(
          "Le signataire du présent formulaire déclare être dûment habilité par le client pour la signature du présent document.",
          40,
          235
        );
        doc.setFont("helvetica", "normal");
      }
      doc.setDrawColor(0);
      doc.setFillColor(200, 200, 200);
      doc.rect(35, 258, 530, 15, "FD");
      doc.rect(35, 258, 530, 200, "D");
      doc.setFont("helvetica", "bold");
      doc.setTextColor(255);
      doc.text("TIERS (professionnel)", 40, 268);
      doc.setTextColor(0);
      doc.setFont("helvetica", "normal");
      doc.text("Entreprise", 40, 285);
      doc.text(
        "Dénomination sociale : PILOTE IMMO     Forme juridique : SAS",
        40,
        300
      );
      doc.text(
        "N° d’identification (SIRET) : 850 295 536 00013     Activité (code NAF) : 6831 Z",
        40,
        315
      );
      doc.text("Adresse : 54 quai Perrière - 38000 GRENOBLE", 40, 330);
      doc.setFont("helvetica", "bold");
      doc.text("Représenté par :", 40, 360);
      doc.setFont("helvetica", "normal");
      doc.text("M. COLLET Emmanuel ", 40, 375);
      doc.text(
        "Adresse professionnelle : 54 quai Perrière - 38000 GRENOBLE",
        40,
        390
      );
      doc.text(
        "N° téléphone : 04 57 38 64 99       E-mail : emmanuel.collet@pilote.immo",
        40,
        405
      );
      doc.rect(35, 458, 530, 200, "D");
      doc.setFontSize(7);
      doc.text(this.accept1, 40, 470);
      doc.setFont("helvetica", "bold");
      doc.text(this.accept2, 145, 470);
      doc.setFont("helvetica", "normal");
      doc.text(this.accept3, 474, 470);
      doc.text(this.accept4, 40, 480);
      doc.text(this.accept5, 40, 490);
      doc.setFont("helvetica", "bold");
      doc.text(this.accept6, 212, 490);
      doc.setFont("helvetica", "normal");
      doc.setFontSize(9);
      doc.rect(40, 500, 5, 5, "D");
      doc.rect(40, 515, 5, 5, "D");
      doc.rect(40, 530, 5, 5, "D");
      doc.rect(40, 545, 5, 5, "D");
      if (this.histo === true) {
        doc.text(
          "x L’historique des consommations, en kWh, du site (et puissances atteintes et dépassements de puissance) ;",
          40,
          505
        );
      } else {
        doc.text(
          "   L’historique des consommations, en kWh, du site (et puissances atteintes et dépassements de puissance) ;",
          40,
          505
        );
      }
      if (this.conso === true) {
        doc.text(
          "x L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site ;",
          40,
          520
        );
      } else {
        doc.text(
          "   L’historique des relevés d’index quotidiens, en kWh, et la puissance maximale quotidienne, en kVA ou kWh, du site ;",
          40,
          520
        );
      }
      if (this.relev === true) {
        doc.text("x L’historique de courbe de charge du site * ;", 40, 535);
      } else {
        doc.text("   L’historique de courbe de charge du site * ;", 40, 535);
      }
      if (this.contrat === true) {
        doc.text(
          "x Les données techniques et contractuelles disponibles du site **.",
          40,
          550
        );
      } else {
        doc.text(
          "   Les données techniques et contractuelles disponibles du site **.",
          40,
          550
        );
      }
      doc.text(
        "Usage des données (conseil énergétique, études, ...) : " + this.usage,
        40,
        570
      );
      doc.text(this.accept7 + this.duree + this.accept8, 40, 585);
      doc.setFontSize(7);
      doc.text(this.accept9, 40, 595);
      doc.text(this.accept10, 40, 605);
      doc.text(this.accept11, 40, 615);

      doc.setDrawColor(0);
      doc.setFillColor(200, 200, 200);
      doc.rect(35, 658, 250, 15, "FD");
      doc.rect(35, 658, 250, 80, "D");
      doc.setFont("helvetica", "bold");
      doc.setTextColor(255);
      doc.text("Date", 40, 668);
      doc.setTextColor(0);
      doc.text("Fait à : " + this.faita, 40, 685);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + "/" + mm + "/" + yyyy;
      if (this.faitle === "Aujourd'hui") {
        doc.text("Le : " + today, 40, 700);
      } else {
        doc.text("Le : " + this.faitle, 40, 700);
      }
      doc.setDrawColor(0);
      doc.setFillColor(200, 200, 200);
      doc.rect(315, 658, 250, 15, "FD");
      doc.rect(315, 658, 250, 80, "D");
      doc.setTextColor(255);
      doc.text("Signature du Client + cachet le cas échéant", 320, 668);
      doc.setTextColor(0);

      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);
      doc.line(40, 790, 100, 790);
      doc.text(
        "** Ensemble de valeurs moyennes horodatées de la puissance active ou réactive injectée ou soutirée, sur des périodes d’intégration consécutives et de même durée.",
        40,
        800
      );
      doc.text(
        "** Caractéristiques du raccordement, du dispositif de comptage et informations relatives au contrat de fourniture connues d’Enedis (puissance souscrite, option",
        40,
        810
      );
      doc.text("    tarifaire d’acheminement, etc.)", 40, 820);

      //doc.text("Nom : " + this.name, 80, 85);
      //doc.text("Prénom : " + this.firstname, 300, 85);
      //doc.text("Adresse : " + this.address, 40, 95);
      if (this.select0 === "Particulier") {
        doc.save("Attest_ENEDIS_" + this.name + ".pdf");
      } else {
        doc.save("Attest_ENEDIS_" + this.denomination + ".pdf");
      }
    },
    reload() {
      location.reload();
    }
  }
};
</script>
